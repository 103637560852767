body {
	margin: 0;
	padding: 0;
	font-family: Roboto, sans-serif;
}
.menu {
	display: flex;
	justify-content: space-between;
	position: relative;
	z-index: 1;
	padding: 20px;
	max-width: 100%;
	margin: 0px auto 0px auto;
	border-bottom: 2px solid rgb(216, 215, 215);
}
@media (max-width: 650px) {
	.menu {
		flex-direction: column;
		align-items: center;
	}
}
.menu .item {
	padding: 18px 28px;
	cursor: pointer;
	transition: color 0.3s ease-out;

	color: #111;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	font-size: 12px;
	margin: 5px 0;
}
.menu .item.active {
	color: #fff;
}
.menu .indicator {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	border-radius: 30px;
}

.not-yet-ready {
	text-decoration: line-through;
}
